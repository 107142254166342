<template>
  <div class="create-user-container step-1">
    <div class="header">Дополнительный аккаунт</div>
    <div class="buttons-container">
      <CamsButton @click="$emit('create')" icon-type="only">
        <img src="@/assets/img/icons/icon-add.svg" class="icon-add-user">
        Создать аккаунт
      </CamsButton>
    </div>

    <div v-for="account in existingAccounts" :key="account.id" class="account-card" @click="$emit('edit', account)">
      <svg class="icon-account">
        <use xlink:href="/img/symbol-defs-v2.svg#icon-box" />
      </svg>
      <div class="account-info">
        <div class="account-name">{{ account.username }}</div>
        <div class="account-cameras">Добавлено камер: {{ account.cameras_count }}</div>
      </div>
      <img src="@/assets/img/icons/right-arrow.svg" class="icon icon-right">
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import { ACTION_LOAD_SUB_USERS } from '@/store/users/index.js';

export default {
  data() {
    return {
      existingAccounts: [],
      isLoading: false,
    };
  },
  mounted() {
    this.loadAccounts();
  },
  methods: {
    async loadAccounts() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(`users/${ACTION_LOAD_SUB_USERS}`);
        this.existingAccounts = response.results;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@use "./style.scss";
</style>
