<template>
  <div>
    <SubUsersList
      v-if="step === 'list'"
      @create="step = 'create'"
      @edit="openEdit" />

    <SubUserCreate
      v-if="step === 'create'"
      @created="onCreated"
      @cancel="step = 'list'" />

    <SubUserEdit
      v-if="step === 'edit'"
      :account="selectedAccount"
      @updated="step = 'list'"
      @deleted="step = 'list'"
      @cancel="step = 'list'" />
  </div>
</template>


<script>
import SubUsersList from './subUsers/SubUsersList.vue';
import SubUserCreate from './subUsers/SubUserCreate.vue';
import SubUserEdit from './subUsers/SubUserEdit.vue';

export default {
  components: { SubUsersList, SubUserCreate, SubUserEdit },
  data() {
    return {
      step: 'list',
      selectedAccount: null,
    };
  },
  methods: {
    openEdit(account) {
      this.selectedAccount = account;
      this.step = 'edit';
    },
    onCreated(newAccount) {
      this.selectedAccount = {
        username: newAccount[0].username,
        password: newAccount[0].password,
        about: newAccount[0].about,
        token: newAccount[0].token,
        cameras: newAccount[1].map(num => ({ number: num }))
      };

      this.step = 'edit';
    },
  },
};
</script>
