<template>
  <div class="create-user-container step-2">

    <div class="header">
      <CamsButton
        class="back-button"
        icon-type="only"
        @click="$emit('cancel')"> <svg>
      <use xlink:href='#icon-arrow-right' class="icon">
      </use></svg>
      </CamsButton>
      Создать аккаунт
    </div>
    <SmartInputText
      v-model="accountName"
      placeholder="Введите название"
      :error="errorUsers[0]?.accountName || ''"
    />

    <div class="buttons-container">
      <CamsButton @click="openDialogAddCameraToUser" icon-type="only">
        <img v-if="!selectedCameras.length" src="@/assets/img/icons/icon-add.svg" class="icon-add-user">
        <svg v-else class="icon-add-camera">
          <use xlink:href="/img/symbol-defs-v2.svg#icon-camera" />
        </svg>
        {{ selectedCameras.length ? `Добавлено камер: ${selectedCameras.length}` : "Выберите камеры" }}
      </CamsButton>
    </div>

    <button class="create-button" @click="createAccount">Создать</button>
    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import AddCameraToUser from "@/components/users/AddCameraToUser.vue";
import { methodsForDialogMixin } from "camsng-frontend-shared/components/dialogs/mixins";
import { ACTION_CREATE_SUB_USER } from '@/store/users/index.js';
import {editEntityMixin} from "@/components/pacs/mixins.js";

export default {
  mixins: [methodsForDialogMixin, editEntityMixin],
  data() {
    return {
      actionForSave: `users/${ACTION_CREATE_SUB_USER}`,
      accountName: '',
      selectedCameras: [],
      isLoading: false,
      errorUsers: [],
    };
  },
  methods: {
    async createAccount() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(`users/${ACTION_CREATE_SUB_USER}`, {
          accountName: this.accountName,
          selectedCameras: this.selectedCameras,
        });
        this.$emit('created', [response, this.selectedCameras]);
      }
      catch (error){
        this.errorUsers = error
      }
      finally {
        this.isLoading = false;
      }
    },
    openDialogAddCameraToUser() {
      this.$camsdals.open(
        AddCameraToUser,
        { initialSelectedCameraNumbers: this.selectedCameras },
        { dialogTitle: "Добавьте камеры" },
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside",
          onClose: data => {
            if (data?.selectedCameras) {
              this.selectedCameras = data.selectedCameras;
            }
          },
        }
      );
    },
  },
};
</script>
<style lang="scss">
@use "./style.scss";
</style>
