<template>
  <div class="create-user-container step-3">
    <div class="header">
      <CamsButton
        class="back-button"
        icon-type="only"
        @click="$emit('cancel')"> <svg>
        <use xlink:href='#icon-arrow-right'>
        </use></svg>
      </CamsButton>
      Дополнительный аккаунт</div>

    <div class="info-list">
      <div v-for="(item, key) in accountInfo" :key="key" class="info-item">
        <div class="item-label">{{ item.label }}</div>
        <div class="item-content" :class="{ 'editing-name': key === 'name' }">
          <SmartInputText
            v-if="key === 'name'" v-model="accountName"
            :error="errorUsers[0]?.accountName || ''"
            style="width: 100%"/>
          <span v-else class="item-text">{{ item.value }}</span>
          <button v-if="key !== 'name'" class="copy-button" @click="copyToClipboard(item.value)">
            <svg class="icon item-icon">
              <use xlink:href="#icon-copy-to-clipboard" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <div class="buttons-container">
      <CamsButton @click="openDialogAddCameraToUser">
        <svg class="icon-add-camera">
          <use xlink:href="/img/symbol-defs-v2.svg#icon-camera" />
        </svg>
        {{ selectedCameras.length ? `Добавлено камер: ${selectedCameras.length}` : "Выберите камеры" }}
      </CamsButton>
    </div>

    <div class="account-actions">
      <CamsButton class="delete-button" @click="deleteAccount">Удалить аккаунт</CamsButton>
      <CamsButton class="reset-button"  @click="resetPassword">Сбросить пароль</CamsButton>
      <CamsButton class="reset-button"  @click="saveAccount">Сохранить</CamsButton>
    </div>

    <SpinnerLoadingModal v-if="isLoading" />
  </div>
</template>

<script>
import AddCameraToUser from "@/components/users/AddCameraToUser.vue";
import { methodsForDialogMixin } from "camsng-frontend-shared/components/dialogs/mixins";
import {
  ACTION_EDIT_SUB_USER,
  ACTION_DELETE_SUB_USER,
  ACTION_RESET_PASSWORD_SUB_USER
} from '@/store/users/index.js';

export default {
  mixins: [methodsForDialogMixin],
  props: ['account'],
  data() {
    return {
      errorUsers: [],
      accountName: this.account.about,
      selectedCameras: this.account.cameras.map(c => c.number),
      accountInfo: {
        name: { label: "Название", value: this.account.about },
        service: { label: "Сервис", value: location.origin },
        login: { label: "Логин", value: this.account.username },
        password: { label: "Пароль", value: this.account.password || "*******"},
        token: { label: "Токен", value: this.account.token }
      },
      isLoading: false,
    };
  },
  methods: {
    async saveAccount() {
      this.isLoading = true;
    try {
      await this.$store.dispatch(`users/${ACTION_EDIT_SUB_USER}`, {
        username: this.account.username,
        accountName: this.accountName,
        selectedCameras: this.selectedCameras,
      });
      this.$emit('updated');
      this.$notify({
        group: "main",
        text: "Изменения сохранены",
        duration: 2000,
        type: "success"
      });
    }        catch (error){
      this.errorUsers = error
    }
      this.isLoading = false;

    },
    async resetPassword() {
      this.isLoading = true;
      try {
        const response = await this.$store.dispatch(`users/${ACTION_RESET_PASSWORD_SUB_USER}`, {
          userName: this.account.username
        });
        // Обновляем пароль в интерфейсе
        this.accountInfo.password.value = response.password;
        this.$notify({
          group: "main",
          text: "Пароль сброшен",
          duration: 2000,
          type: "success"
        });
      } catch (error) {
        console.error('Ошибка сброса пароля:', error);
        this.$notify({
          group: "main",
          text: "Ошибка сброса пароля",
          duration: 2000,
          type: "error"
        });
      } finally {
        this.isLoading = false;
      }
    },
    async deleteAccount() {
      this.isLoading = true;
      await this.$store.dispatch(`users/${ACTION_DELETE_SUB_USER}`, {
        userName: this.account.username
      });
      this.isLoading = false;
      this.$emit('deleted');
      this.$notify({ group: "main", text: "Пользователь удален", duration: 2000, type: "success" });
    },
    openDialogAddCameraToUser() {
      this.$camsdals.open(
        AddCameraToUser,
        { initialSelectedCameraNumbers: this.selectedCameras },
        { dialogTitle: "Добавьте камеры" },
        {
          size: "vuedal-auto-width vuedal-all-height",
          name: "js-click-outside",
          onClose: data => {
            if (data?.selectedCameras) {
              this.selectedCameras = data.selectedCameras;
            }
          },
        }
      );
    },
    copyToClipboard(value) {
      navigator.clipboard.writeText(value);
      this.$notify({ group: "main", text: "Скопировано!", duration: 2000, type: "success" });
    },
  },
};
</script>
<style lang="scss">
@use "./style.scss";
</style>
