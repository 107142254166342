import {reformatErrorsForm} from "@/store/pacs/helpers.js";

/**
 * Отдельный vuex модуль для взаимодействия по user_links-API
 */

// actions для users. Вызов действия начинать с "users/"
export const ACTION_LOAD_CHILD_LINKS = "LOAD_CHILD_LINKS";
export const ACTION_ADD_CHILD_LINKS = "ADD_CHILD_LINKS";
export const ACTION_DELETE_CHILD_LINKS = "DELETE_CHILD_LINKS";
export const ACTION_LOAD_PARENT_LINKS = "LOAD_PARENT_LINKS";
export const ACTION_DELETE_PARENT_LINKS = "DELETE_PARENT_LINKS";
export const ACTION_LOAD_USER_TOKENS = "LOAD_USER_TOKENS";
export const ACTION_CREATE_USER_TOKEN = "CREATE_USER_TOKEN";
export const ACTION_DELETE_USER_TOKEN = "DELETE_USER_TOKEN";
export const ACTION_LOAD_SUB_USERS = "LOAD_SUB_USERS";
export const ACTION_CREATE_SUB_USER = "CREATE_SUB_USER";
export const ACTION_EDIT_SUB_USER = "EDIT_SUB_USER";
export const ACTION_DELETE_SUB_USER = "DELETE_SUB_USER";
export const ACTION_RESET_PASSWORD_SUB_USER = "RESET_PASSWORD_SUB_USER";

/**
 * Перечисление констант с названиями всех полей.
 */
export const FIELDS_USER_LINK = Object.freeze({
  "user_id": "user_id",
  "username": "username",
  "date": "date",
});
export const FIELDS_USER_TOKEN = Object.freeze({
  "id": "id",
  "username": "username",
  "body": "body",
});
export const FIELDS_SUBUSERS = Object.freeze({
  "id": "id",
  "username": "username",
  "about": "about",
  "token": "token",
  "cameras": "cameras",
  "cameras_count": "cameras_count",

});

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    /**
     * Загрузка списка дочерних связей учетной записи.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_CHILD_LINKS]() {
      const response = await this.getters.ajaxClient.post("/v0/user_links/child/");
      return response.data;
    },
    /**
     * Добавление связи, требуется передать логин и пароль учетной записи.
     *
     * @param {String} username
     * @param {String} password
     * @return {Promise}
     */
    async [ACTION_ADD_CHILD_LINKS](context, {username, password}) {
      try {
        await this.getters.ajaxClient.post("/v0/user_links/child/add/", {
          username,
          password,
        });
      } catch (error) {
        throw Object.values(error.response.data.error).join("");
      }
    },
    /**
     * Удаление связи с дочерней учетной записью.
     *
     * @param {Object} context
     * @param {Number} childId
     * @return {Promise}
     */
    async [ACTION_DELETE_CHILD_LINKS](context, childId) {
      try {
        await this.getters.ajaxClient.post("/v0/user_links/child/delete/", {user_id: childId});
      } catch (error) {
        throw Object.values(error.response.data.error).join("");
      }
    },
    //Parent_________________________________________________________________________________________________________
    /**
     * Загрузка списка родительских связей учетной записи.
     *
     * @return {Promise}
     */
    async [ACTION_LOAD_PARENT_LINKS]() {
      const response = await this.getters.ajaxClient.post("/v0/user_links/parent/");
      return response.data;
    },
    /**
     * Удаление связи с родительской учетной записью.
     *
     * @param {Object} context
     * @param {Number} parentId
     * @return {Promise}
     */
    async [ACTION_DELETE_PARENT_LINKS](context, parentId) {
      try {
        await this.getters.ajaxClient.post("/v0/user_links/parent/delete/", {user_id: parentId});
      } catch (error) {
        throw Object.values(error.response.data.error).join("");
      }
    },
    // Работа с токенами доступа
    async [ACTION_LOAD_USER_TOKENS]() {
      const response = await this.getters.ajaxClient.post("/v0/tokens/long/", {
        fields: [
          FIELDS_USER_TOKEN.id,
          FIELDS_USER_TOKEN.body,
          FIELDS_USER_TOKEN.username
        ]
      });
      return response.data.results[0];
    },
    async [ACTION_CREATE_USER_TOKEN]() {
      const response = await this.getters.ajaxClient.post("/v0/tokens/long/create/",{});
      return response.data;
    },
    async [ACTION_DELETE_USER_TOKEN](context, {ids}) {
      const response = await this.getters.ajaxClient.post("/v0/tokens/long/delete/", {
        ids
      });
      return response.data.results;
    },
    async [ACTION_LOAD_SUB_USERS]() {
      const response = await this.getters.ajaxClient.post("/v0/subusers/", {
        fields: [
          FIELDS_SUBUSERS.id,
          FIELDS_SUBUSERS.token,
          FIELDS_SUBUSERS.about,
          FIELDS_SUBUSERS.cameras,
          FIELDS_SUBUSERS.username,
          FIELDS_SUBUSERS.cameras_count,
        ]
      });
      return response.data;
    },
    async [ACTION_CREATE_SUB_USER](context, {accountName, selectedCameras}) {
    try{
      const response = await this.getters.ajaxClient.post("/v0/subusers/create/", {
        about: accountName,
        camera_numbers: selectedCameras,
      });
      return response.data;
    } catch (error) {
      const apiDataErrors = reformatErrorsForm(error.response.data.fields),
        dataErrors = {
          accountName: apiDataErrors["about"],
        };
      throw [dataErrors, error.response.data];
    }
    },
    async [ACTION_EDIT_SUB_USER](context, {username, accountName, selectedCameras}) {
      try {
        const response = await this.getters.ajaxClient.post("/v0/subusers/edit/", {
          username,
          about: accountName,
          camera_numbers: selectedCameras,
        });
        return response.data;
      }catch (error) {
        const apiDataErrors = reformatErrorsForm(error.response.data.fields),
          dataErrors = {
            accountName: apiDataErrors["about"],
          };
        throw [dataErrors, error.response.data];
      }
    },
    async [ACTION_DELETE_SUB_USER](context, {userName}) {
       await this.getters.ajaxClient.post("/v0/subusers/delete/", {
        username: userName,
      });
    },
    async [ACTION_RESET_PASSWORD_SUB_USER](context, {userName}) {
      const response = await this.getters.ajaxClient.post("/v0/subusers/change_password/", {
        username : userName,
      });
      return response.data;
    },
  }
};
